<template>
  <div class="enjoy">
    <img class="iconimg" src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/sichuan/hotvip_bg01.png">
    <div class="scroll_tips">
      <ul class="scroll_box">
        <li class="scroll_item">
          176****6142抢到了
        </li>
        <li class="scroll_item">
          131****7394抢到了
        </li>
        <li class="scroll_item">
          155****4549抢到了
        </li>
        <li class="scroll_item">
          130****5436抢到了
        </li>
        <li class="scroll_item">
          156****0054抢到了
        </li>
        <li class="scroll_item">
          171****5245抢到了
        </li>
        <li class="scroll_item">
          175****6540抢到了
        </li>
        <li class="scroll_item">
          185****0974抢到了
        </li>
      </ul>
    </div>
    <div class="form">
      <img class="one" v-if="num == 1" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif" />
      <van-field v-model="form.phone" type="tel" maxlength="11" placeholder="请输入您的手机号码" @input="changePhone" />
      <img class="two" v-if="num == 2" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif" />
      <img class="three" v-if="num == 3" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif" />
      <template>
        <van-field v-model="form.code" type="number" center maxlength="6" autocomplete="one-time-code"
          placeholder="请输入短信验证码" :class="['input-code', { 'two-cell': num == 2 }]">
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>

      <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/zj/enjoy_btn.gif" class="submit" @click="onSubmit" />
      <img class="four" v-if="num == 4" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_06.gif">
      <div class="checked">
        <van-checkbox checked-color="#fb663e" v-model="checked" icon-size="16px">
          我已阅读并同意<span class="book" @click="showRule">《活动规则》</span>和<span class="book"
            @click="showDialog">《个人信息保护授权协议》</span>
        </van-checkbox>
        <div>29元彩铃云盘权益N选2流量组合包，立即生效</div>
      </div>
    </div>
    <img class="iconimg img1" src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/sichuan/hotvip_bg02.png">
    <img class="iconimg img2" src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/sichuan/hotvip_bg03.png">
    <img class="iconimg" src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/sichuan/hotvip_bg04.png">
    <img class="iconimg" src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/sichuan/hotvip_bg05.png">
    <div class="info">

      <div class="title-img">☛ 活动说明 ☚</div>
      <div class="info-text">一、适用范围</div>
      <div class="info-text">未订购与本产品有互斥关系产品，且符合产品办理条件的四川联通手机用户。</div>
      <div class="info-text">
        <span>温馨提示：因受用户套餐、号码使用状态、产品规则变化等影响，是否能订购该产品以办理当时系统提示为准。</span>
      </div>
      <div class="info-text">二、产品介绍</div>
      <div class="info-text">产品名称：“29元彩铃云盘权益N选2流量组合包”，<span>29元/月</span>，包含：</div>
      <div class="info-text">
        1、联通安全管家，包含（通话安全、短信安全、亲情守护三大功能）；<br>
        2、联通云盘乐享会员：会员特权/尊贵身份标识+云盘储存容量100G;<br>
        3、视频彩铃钻石会员，包含（视频彩铃+互联网热门权益N选2），权益产品种类因受市场变化和领取情况等因素影响，可能会随时调整更新、上线或下线，具体可领取权益请以领取页面展示为准。<br>
        4、产品包含8GB区域流量(四川省内使用)，订购副卡独享。可结转。区域流量优先国内流量。该产品包含流量及5G速率无法解除限速产品的限速阈值。<br>
        5、以上产品为组合销售，不可单独取消，单独订购
      </div>
      <div class="info-text">三、生效规则： </div>
      <div class="info-text"><span>订购后立即生效，如不取消，每月自动续订。会员权益领取机会当月有效，需每月领取，过期不补。</span></div>
      <div class="info-text">四、计费规则:</div>
      <div class="info-text"><span>连续包月:订购后立即扣费，如不取消，每月1号自动续订并从话费中扣取产品费用29元。</span></div>
      <div class="info-text">五、业务退订：</div>
      <div class="info-text"><span>如需退订请向中国联通客服热线、中国联通APP、营业厅等咨询办理。</span></div>
      <div class="info-text">六、退订规则：</div>
      <div class="info-text"><span>当月退订，次月生效，次月不再收取包月费。会员权益领取机会当月有效，需每月领取，过期不补。请在退订前完成权益领取。</span></div>
      <div class="info-text">七、使用说明:</div>
      <div class="info-text">
        （1）含8GB省内流量。流量共享规则：①智慧沃家共享版用户订购，仅限本人使用，他人不共享（此项规则在智慧沃家共享版用户可以办理该产品时适用，若受业务办理规则限制，智慧沃家共享版用户订购失败，则此项规则不适用）。②主副卡用户中主卡订购，副卡可共享包内流量。副卡订购仅限副卡使用。（规则②部分仅面对主卡是实卡的主副卡关系。所有智慧沃家用户订购，请参考共享规则①）<br>
        （2）安全管家：包含通话安全、短信安全和亲情守护三大功能。通话安全是对多种类型的骚扰电话、疑似诈骗电话提供来电提醒和拦截服务。短信安全是对国际短信进行风险提醒。亲情守护在移网侧是对被守护人的亲情号码的通话安全、手机上网安全向守护人进行提醒；在固网侧对接入家庭网关的终端可按时间、按应用进行上网管理。<br>
        （3）联通云盘100G乐享会员：会员特权/尊贵身份标识:乐享会员;空间容量:100G;创建家庭数:10,家庭成员数:20;大文件上传:1G回收站有效期:7天。<br>
        （4）视频彩铃钻石会员服务，可登录联通视频彩铃APP、联通视频彩铃小程序、中国联通APP视频彩铃专区等查看使用。包含的热门APP会员权益，点击订购通知短信链接，<a
          href="https://huodong.10155.com/h5/hdact4/diamondVip4_lottery/#/?chl=1f053fa95d57781b42df5a670d63dfb4">t.10155.com/psg</a>，登录后可领取权益。权益产品种类因受市场变化和领取情况等因素影响，可能会随时调整更新、上线或下线，具体可领取权益请以领取页面展示为准。<br>
        （5）5G基础服务包（5G上网服务（下行峰值300Mbps））。温馨提示：您办理5G包类资费，持5G终端可登录5G网络，享受更高的网速；持4G终端无法使用5G网络，但可使用产品流量；您可通过手厅APP5G专区查询本地区5G网络覆盖情况。<br>
        （6）权益内容：一刻talks会员月卡：下载“一刻talks”app,享会员福利，全站免费观看精品付费视频和完整版演讲局视频。2024年12月31日前订购用户赠送“一刻talks会员月卡”，“一刻talks会员月卡”赠送至2024年12月31日。
      </div>
      <div class="info-text">八、办理规则：</div>
      <div class="info-text">因受用户套餐、号码使用状态、产品规则变化等影响，是否能订购该产品以办理当时系统提示为准。</div>
      <div class="info-text">九、通过本页面下单成功不代表产品订购成功，订购成功以收到产品订购成功短信通知为准。</div>
      <div class="info-text">十、客服电话:4001189599（7天*24小时）</div>
      <div class="info-text">产品服务:联通在线信息科技有限公司</div>
      <div class="info-text">产品支持:浙江秋末信息科技有限公司</div>
    </div>
    <img class="iconimg" src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/sichuan/hotvip_biaoshi.png" />
    <dialog-alert :visibility.sync="alert.visibility" :title="alert.title" :message="alert.message" />
    <!-- 二确 -->
    <BaseDialogConfirm :visibility.sync="BaseDialogvisibility" :closeBtnImg="closeBtnImg" :submitBtnImg="submitBtnImg"
      :background="background" />
  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index";
import DialogAlert from "./dialog/alert.vue";
import BaseDialogConfirm from "./dialog/confirm.vue";
import tailJumpMixin from "@/utils/tailJump.js";
import { liuliangguo } from "@/utils/common.js";

export default {
  mixins: [tailJumpMixin],
  components: {
    DialogAlert,
    BaseDialogConfirm
  },
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        productNo: "814204282064",
        phone: "",
        characteristic: "",
        marketUrl: "",
        code: "", //绑定输入验证码框框
        sid: "",
        data: "",
        orderId: "",
      },
      num: 1,
      dialog: false,
      checked: false,
      BaseDialogvisibility: false,
      closeBtnImg: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/henan/alert_cancel.png",
      submitBtnImg: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/henan/alert_ok.png",
      background: "https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/LT/sichuan/hotvip_alertbg01.png",
      alert: {
        visibility: false,
        title: "",
        message: "",
      },
    };
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      phone,
      characteristic: from + reasonCode,
      marketUrl: encodeURIComponent(href)
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  mounted() {
    const that = this;
    const phone = that.form.phone || "";
    window.onpopstate = function (event) {
      // 返回拦截
      liuliangguo("103813", phone, "29元彩铃云盘权益N选2流量组合包");
    };
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async changePhone() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone = "" } = this.form;

      if (!filterPhone.test(phone)) return;
      localStorage.setItem("phone", phone);
      this.checked = true;
      this.getHnPhoneCheck();
    },
    async getHnPhoneCheck() {

    },
    getAuthCode() {
      if (this.yzm_loading) return;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        this.yzm_loading = true;
        try {
          getVerificationCode(JSON.stringify({ ...this.form, appPackage: navigator.userAgent }))
            .then((res) => {
              this.yzm_loading = false;
              if (res.code !== 0) {
                this.$dialog.alert({ message: res.message || '验证码获取失败' });
                liuliangguo("103810", this.form.phone, "29元彩铃云盘权益N选2流量组合包");
              } else {
                // 设置倒计时秒
                this.sendAuthCode = false;
                this.auth_time = 120;
                let auth_timetimer = setInterval(() => {
                  this.auth_time--;
                  if (this.auth_time <= 0) {
                    this.sendAuthCode = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              }
            })
            .catch((error) => {
              this.yzm_loading = false;
            });
        } finally {
          this.yzm_loading = false;
        }
      }
    },
    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.$toast.clear();
        this.dialog = false;
        if (res.code !== 0) {
          this.$toast({
            duration: 8000,
            message: res.message || '提交失败',
          });
          liuliangguo("103812", this.form.phone, "29元彩铃云盘权益N选2流量组合包");
          // liuliangguo("103695", this.form.phone, "29元彩铃云盘权益N选2流量组合包");
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "center",
          });
          liuliangguo("103811", this.form.phone, "29元彩铃云盘权益N选2流量组合包");
        }
      });
    },
    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else if (!this.checked) {
        this.$toast({
          duration: 8000,
          message: "请阅读并同意相关协议",
        });
      } else {
        this.BaseDialogvisibility = true;
        // this.handleSubmit();
      }
    },
    showDialog() {
      this.$dialog
        .alert({
          messageAlign: "left",
          title: '个人信息保护授权协议',
          message: `尊敬的客户：  
联通与其运营浙江秋末信息科技有限公司（下称“我们”）非常重视用户（下称“您”）的隐私和个人信息保护。您在使用我们的产品与/或服务时， 我们可能会收集和使用您的相关信息。我们希望通过《隐私权政策》（下称“隐私政策”）向您说明我们在您使用我们的产品与服务时如何收集、使用、 保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。电话用户真实身份信息登记规定是为了规范电话用户真实 身份信息登记活动，保障电话用户和电信业务经营者的合法权益，维护网络信息安全，促进电信业的健康发展而制定的法规，2013年6月28日，中华人民 共和国工业和信息化部第2次部务会议审议通过，2013年7月16日中华人民共和国工业和信息化部令第25号公布，自2013年9月1日起施行。

本政策将帮助您了解以下内容：
1、我们如何收集和使用您的个人信息
2、我们如何使用 Cookie 和同类技术
3、我们如何共享、转让、公开披露您的个人信息
4、我们如何保护和保存您的个人信息
5、您如何管理个人信息
6、未成年人信息的保护
7、通知和修订
8、如何联系我们
  请您在使用我们的产品与服务前仔细阅读并确认您已经充分理解本政策的内容，您使用或在我们更新本隐私政策后继续使用我们的产品与服务， 即视为您同意本隐私政策内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。
  如对本隐私政策有任何问题，您可随时通过我们的在线客服与我们联系。
一、我们如何收集和使用您的个人信息
 【联通】收集用户的两类信息:
个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
我们在提供产品和服务的过程中可能涉及的个人信息包括：基本信息（包括个人姓名、住址、个人电话号码）；网络身份标识信息 （包括系统账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人财产信息（交易和消费记录）；个人常用设备信息 （包括硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内 的描述个人常用设备基本情况的信息）。个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康 受到损害或歧视性待遇等的个人信息，本隐私政策中涉及的个人敏感信息包括：您的个人身份信息（包括身份证）；其他信息（包括个人手机号码）。
我们基于以下目的收集和使用您的个人信息：
（一）您须授权我们收集和使用您个人信息的情形
我们的产品与/或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能、改进我们的产品与/或服务所必须的功能及保障交易安全所必须的功能。 我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：
1、实现网上购物所必须的功能
（1）用户注册
您首先需要在线申请一张联通。当您注册时，您需要向我们提供您的姓名、手机号码、收货地址。这些信息均属于您的“账户信息”。在我们的功能支持 的情况下，您可以补充信息，帮助我们为您提供个性化的商品推荐和更优的购物体验，但如果您不提供这些补充信息，不会影响您网上购物的基本功能。
（2）商品信息展示和搜索
为了让您快速地找到您所需要的商品，基于系统安全风控的考虑，也为了不断改进和优化我们的产品功能，我们可能会收集您使用我们的产品与/或服务的 设备信息（包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、浏览器类型来为您提供 商品信息展示的最优方式。
您可以通过搜索来精准地找到您所需要的商品或服务。我们将会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品。请您放心 ，搜索关键词信息无法单独识别和定位到您的身份，不属于您的个人信息。只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时， 这些搜索关键词信息才被界定为您的个人信息，我们会将这些信息与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。
（3）下达订单
当您下订单时，您需要在订单中填写、确认您的姓名、联系方式、收货地区、短信验证码、详细地址、身份证号，同时该订单中会载明订单号、购买的 商品或服务信息、应支付的货款金额。 上述订单信息，将作为与您交易的凭证，用于对您的身份核验、确定交易、支付结算、配送。我们也基于此为您提供查询订单、提供客服咨询、售后服务。为了 维护您、第三人及微卡平台的权益，我们还会使用您的订单信息来判断您的交易是否存在异常并采取合理措施。
（4）支付结算
在确认订单后，您可以通过联通合作的第三方支付机构所提供的支付服务。我们需要将您的订单号与交易金额信息与这些支付机构共享以实现您的支付 并确认您的支付已完成。
（5）商品或服务的交付
当您成功支付订单金额后，我们将您的订单信息通知合作的销售商，由其安排第三方配送公司为您的订单进行配送和交付。您知晓并同意上述销售商及第 三方配送公司会基于商品配送目的使用您的订单信息以保证您的订购的商品能够安全送达，我们已经通过协议要求这些第三方对您的订单信息予以严格保密。 第三方配送公司人员提供配送服务的同时，可能会基于法律法规的要求进行实名认证，此环节仅用户身份认证，且操作人员须遵守公司保密制度的规定。
（6）客服咨询与售后
我们在客服售后回访、应答您的咨询需求以及处理您的售后要求时会使用您的账号信息和订单信息。为保证您的交易安全，客服系统或客服人员会使用您 的账号信息与您进行身份、订单信息核验，并在您提出变更个人信息时予以信息采集、更改及存储。
2、改进我们的产品与/或服务所必须的功能
我们可能会收集您的订单信息、浏览信息、您的兴趣爱好（可选）进行数据分析以形成用户画像，以匹配您可能感兴趣的商品以提高您的购买决策效率。 我们还可能为了提供服务及改进服务质量的合理需要而获得您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时的答复信息，以及您与 我们的合作伙伴互动时我们获得的相关信息。对于我们收集到的信息，我们可能会将它们进行关联，以便我们能为您提供更好的服务
3、保障交易安全所必须的功能
为提高您使用我们的产品与/或服务时系统的安全性，预防不法分子利用钓鱼网站欺诈、黑客进攻、不法分子数据撞库等威胁您的会员卡交易风险及商城账户安全 ，我们可能会通过了解您的浏览信息、您常用的软件信息、设备信息等手段来判断您所面临的风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也 会收集您的设备信息进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。
（二）您可选择是否授权我们收集和使用您的个人信息的情形
1、为使您购物更便捷或更有乐趣，我们在技术能力具备的情况下会开放并供您选择性使用以下附加功能，这些附加功能的使用与否取决于您是否做出明示 性的选择。在使用这些功能的过程中，我们可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以进行网上购物，只是您将无法因为使用这些 附加功能而获得更多的便捷和乐趣：
（1）基于位置信息的个性化推荐功能：我们会收集您的位置信息（我们仅收集您当时所处的地理位置来判断您所处的地点，并自动为您推荐您所在区域可以 购买的商品或服务。比如向您推荐离您最近的可选的购物商场。
（2）摄像头拍照功能：您可以使用这个附加功能完成视频拍摄、拍照、扫码的功能。
（3）语音识别与处理功能：您可以使用麦克风来进行语音购物或与我们的客服机器人进行咨询和互动。我们会收集您的录音内容以识别您的需求和客服与售后需求。
您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能。您后续可关闭相关权限授权，我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前已经过您授权所进行的个人信息的处理。
（三）在以下情况下，我们收集、使用个人信息无需征得您的授权同意：
1、与国家安全、国防安全有关的；
2、与公共安全、公共卫生、重大公共利益有关的；
3、与犯罪侦查、起诉、审判和判决执行等有关的；
4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
5、所收集的个人信息是个人信息主体自行向社会公众公开的；
6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
7、根据您的要求签订及履行合同所必需的；
8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
9、法律法规规定的其他情形。
（四）我们从第三方获得您个人信息的情形  我们可能从第三方获取您授权共享的账户信息（头像、昵称）如微信授权登录，使您可以通过第三方账户登录并使用我们的产品与服务。我们将会依据 与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
（五）您个人信息使用的规则
1、我们会严格依据本隐私政策的约定对所收集的个人信息进行使用。
2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。我们有权对用户数据库进行分析并予以商业化的利用。
3、您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除、通过系统设置拒绝我们收集、因系统设置了特定的有效期限且已到达期限，否则将在您使用我们的产品和服务期间持续授权我们使用。
4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
5、当我们要将您的个人信息用于超出本政策约定用途时，我们会另行征求您的同意。
二、我们如何使用 Cookie 和同类技术
（一）Cookies的使用
1、为向您提供更便捷的用户体验，简化您重复登录的步骤、存储您的购物偏好或您购物车中的商品等数据进而为您提供购物的偏好设置、优化对广告的选择与互动、判断您的登录状态以及账户或数据安全，我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件。
2、我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有Cookies。但清除后，您可能需要在每一次访问隐私权政策网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。
（二）网络Beacon和同类技术的使用
除 Cookie 外，我们还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些电子图像（称为"单像素" GIF 文件或 "网络 beacon"）。我们使用网络beacon的方式有：
1、通过在网站上使用网络beacon，计算用户访问数量，并通过访问 cookie 辨认注册的用户。
2、通过得到的cookies信息，为您提供个性化服务。
三、我们如何共享、转让、公开披露您的个人信息
（一）共享
1、原则上，我们不会与任何公司、组织和个人共享您的个人信息，但以下情况除外：
（1）事先获得您明确的同意或授权；
（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
（3）在法律法规允许的范围内，为维护我们、我们的关联方或合作伙伴、您或其他用户或社会公众利益、财产或安全免遭损害，合理维权而有必要提供；
（4）只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
（5）应您需求为您处理您与他人的纠纷或争议；
（6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
（7）基于学术研究而使用；
（8）基于符合法律法规、社会公共利益而使用。
2、我们可能会将您的个人信息与我们的关联方（环球微卡）共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
3、我们会基于为您提供服务目的而向合作伙伴等第三方共享您的订单信息、账户信息等，但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们的合作伙伴包括以下类型：
（1）商品或技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货服务、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。
（2）商品销售商或第三方商家。我们必须将您的订单信息与交易有关的必要信息与商品销售商、第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。
（3）委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们产品与/或服务的用户群提供促销推广的服务。我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（“委托方”）共享，但我们会进行相应的匿名处理，以便它不会识别您个人。
4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
（二）转让
我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
1、事先获得您明确的同意或授权；
2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。
（三）公开披露
我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。我们会对所有的请求都进行慎重审查，以确保其合法有据。
四、我们如何保护和保存您的个人信息
（一）我们保护您个人信息的技术与措施 我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
1、数据安全技术措施 我们会采用符合业界标准的安全防护措施，包括建立合理的安全技术，如加密技术来收集、存储，防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失；我们建立健全相应的制度流程规范以确保您的个人信息安全。 我们采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。 我们采用代码安全自动检查、数据访问日志记录与分析技术进行个人信息安全审计。
2、人员意识教育 我们致力于加强安全意识，举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。 数据接触控制
3、我们仅允许有必要知晓这些信息的的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被终止与我们的合作关系。
4、数据事件应急处理 为应对个人信息泄露、损毁和丢失等可能出现的风险，我们建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施。 在发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将通过站内信、邮件、电话、短信等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。 互联网环境并非百分之百安全，我们将尽力对您发送给我们的任何信息进行安全保护。如果因我们的过错，导致在物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。 如果您对我们的个人信息保护有任何疑问，可联系我们的客服予以反馈。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即与我们联系以便我们采取相应措施。
（二）您个人信息的保存
1、您的个人信息将全被存储于中华人民共和国境内。
2、当您申请注销账户后，我们将在您提出申请的30日内完成对您的账户注销审核，审核通过后，我们将对您的个人信息进行删除或匿名化处理。
3、当我们需要终止服务或运营，我们会提前30日通知您，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
五、您如何管理您的个人信息 我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除等权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
1、访问和更正您的个人信息
（1）对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。
（2）您无法访问和更正的个人信息：除上述列明可查询及修改的信息外，您的部分个人信息暂无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息。上述信息我们会在您的授权范围内进行使用，您可联系我们进行删除或做匿名化处理。 2、删除您的个人信息 您可以直接联系我们清除或删除您的个人信息。 在以下情形中，您可以向我们提出删除个人信息的请求：
（1）如果我们处理个人信息的行为违反法律法规；
（2）如果我们收集、使用您的个人信息，却未征得您的同意；
（3）如果我们处理个人信息的行为违反了与您的约定；
（4）如果我们终止服务及运营。若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体另行获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
2、注销账户 您可以联系我们的客服要求注销您的账户，您注销账户后，我们将停止为您提供产品与/或服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息或匿名化处理，并不再进行任何技术利用。 注销本账户后，视为您放弃以下权益：
（1）账户信息、订单信息、会员权益、虚拟资产；
（2）会员卡号、验证手机或邮箱；
（3）解除与第三方授权或绑定关系； 您理解并同意，我们无法协助您重新恢复前述服务，您注销账户的行为会给您的售后维权带来诸多不便，请您在操作之前，确保您的账户无任何纠纷，并自行备份账户相关的所有信息和数据，下载并保存好订单商品和服务的交易凭证、票据（如电子发票）等资料。
3、拒绝推送 如果您不想接受我们给您发送的促销信息，您可以随时回复“TD”来取消我们给您发送的手机促销短信。 您在行使相关权利时遇到问题或有任何意见建议，您均可以通过我们的在线客服与我们联系。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的30天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。 在以下情形中，按照法律法规要求，我们将无法响应您的请求：
（1）与国家安全、国防安全有关的；
（2）与公共安全、公共卫生、重大公共利益有关的；
（3）与犯罪侦查、起诉和审判等有关的；
（4）有充分证据表明您存在主观恶意或滥用权利的；
（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
六、关于未成年人的个人信息保护安排
1、我们非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得监护人的书面同意。我们根据国家相关法律法规的规定保护未成年人的个人信息。
2、对于监护人同意而收集未成年人个人信息的情况，我们在在法律法规允许、监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
3、如果我们发现自己在未事先获得监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据且不做任何其他安排。
七、通知和修订
1、基于国家法律法规的变化，以及为持续优化我们的服务，本隐私政策也会随之更新。但我们不会主动削减您依据本隐私政策所应享有的权利。我们会通过网站、移动端上发出更新版本，也请您访问隐私权政策以便及时了解最新的隐私政策。
2、对于发生服务模式发生重大变化、所有者变更等重大变更，我们还会提供更为显著的通知。`
        });
    },
    showRule() {
      this.alert = {
        visibility: true,
        title: "《活动规则》",
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.enjoy {
  background-color: #e33c50;

  .iconimg {
    width: 100%;
  }

  .img1 {
    margin-top: 40px;
  }

  .img2 {
    width: 94vw;
    margin: 0 3vw;
  }

  .scroll_tips {
    width: 100%;
    margin: 0 auto 0;
    overflow: hidden;

    .scroll_box {
      height: 61px;
      animation: scroll 15s linear infinite;
      display: flex;

      .scroll_item {
        background-color: rgba(0, 0, 0, 0.44);
        border-radius: 40px;
        white-space: nowrap;
        width: 329px;
        text-align: center;
        font-weight: 500;
        flex-shrink: 0;
        line-height: 61px;
        font-size: 30px;
        color: white;
        margin-left: 62px;
      }

      @keyframes scroll {
        0% {
          transform: translateX(0%);
        }

        100% {
          transform: translateX(-317.2%);
        }
      }
    }
  }

  .form {
    position: relative;

    .one,
    .two,
    .three,
    .four {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .one {
      transform: translate(80px, -80px);
    }

    .two {
      width: 166px;
      right: 40px;
      top: 50px;
    }

    .three {
      width: 456px;
      transform: translate(80px, -20px);
    }

    .four {
      width: 310px;
      transform: translate(200px, -94px);
    }

    ::v-deep .van-cell {
      width: 680px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 90px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 46px;
      border: solid 3px #b2b2b2;

      &.input-code {
        margin-top: 45px;

        .code {
          width: 229px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: rgb(81, 169, 255);
          font-size: 30px;
          font-weight: normal;
          border-left: solid 3px #b2b2b2;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;

        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 40;
          color: #969696;
        }
      }
    }

    .submit {
      width: 660px;
      display: block;
      margin: 50px auto 0;

      &.none {
        pointer-events: none;
      }

      &.bottom {
        position: fixed;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 634px;
        bottom: 70px;
      }
    }

    .checked {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;

      ::v-deep .van-checkbox {
        width: auto;
      }

      ::v-deep .van-checkbox__label {
        color: #fff;
      }

      .book {
        font-weight: 400;
        color: #f8f107;
      }
    }
  }



  .info {
    font-size: 24px;
    color: #ffffff;
    line-height: 38px;
    font-weight: 400;
    padding: 0px 28px 40px;

    .title-img {
      width: 251px;
      margin: 50px auto 40px;
      text-align: center;
      font-size: 34px;
      font-weight: 700;
      text-decoration: underline;
    }

    .info-text {
      span {
        color: yellow;
      }

    }

    .info-top {
      margin-top: 20px;
    }

  }

  ::v-deep .footer {
    padding: 586px 0 0 22px;
    justify-content: space-evenly;

    .btn {
      width: 247px;

      &-close {
        width: 249px;
      }
    }
  }

}
</style>
